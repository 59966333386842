<template>

  <div class="market-wrapper">

    <div class="market-name">
      <span class="bold">{{ outcome.market_name }}</span>
    </div>

    <div class="row outcome-row justify-content-md-center">

      <div class="col-12 text-justify"
          v-for="(o,index) in outcome.outcomes"
          :key="index"
          v-bind:id="o.odd_id"
          style="min-height: 2.8em;padding: 7px;"
          v-bind:disabled="isLocked()"
          @click="addOutrightPick(sport_id,tournament_id,outcome.market_name,outcome.market_id,tournament_name,category_name,o)"
          :class="getClasses(o)">

        <div class="">

          <Odd
              v-bind:odd_id="o.odd_id"
              v-bind:show_direction="show_direction"
              v-bind:outcome_name="o.outcome_name"
              v-bind:odds="o.odd"
              v-bind:tournament_id="tournament_id"
              v-bind:previous_odds="o.previous_odds"
              v-bind:outcome_id="o.outcome_id"
              v-bind:specifier="outcome.specifier"
              v-bind:market_id="outcome.market_id"
              v-bind:category_name="category_name"
              v-bind:market_name="outcome.market_name"
              v-bind:sport_id="sport_id"
              v-bind:tournament_name="tournament_name"
              v-bind:pick="o"/>
        </div>

      </div>
    </div>

  </div>

</template>

<script>

import Odd from './Odd'

export default {
  components: {
    Odd
  },
  name: "Outcome",
  props: {
    outcome: {
      type: Object,
      required: true
    },
    tournament_id: {
      required: true
    },
    sport_id: {
      required: true
    },
    tournament_name: {
      required: true
    },
    category_name: {
      required: true
    },
  },
  methods: {
    isLocked: function(){

      return  false;

    },
    getKey: function(index){

      return Math.random().toString(10).replace('0.', 'specifiers-id-' +index+ '-');

    },
    /**
     *
     * @param sport_id
     * @param match_id
     * @param market_name
     * @param market_id
     * @param competitor_1
     * @param competitor_2
     * @param picks
     */
    pick: function (sport_id, match_id, market_name, market_id, competitor_1, competitor_2, picks) {

      var vm = this;

      console.log('addPick match_id=>'+match_id+' market_name =>'+market_name+' market_id=>'+market_id+' picks=>'+JSON.stringify(picks));

      var odd_id = picks.odd_id;
      var bSlip = this.getObject("bslip");

      if (!Array.isArray(bSlip)) {

        bSlip = [];
      }

      var isunselect = false;
      picks.match_id = match_id;
      picks.sport_id = sport_id;
      picks.market_name = market_name;
      picks.market_id = market_id;
      picks.competitor_1 = competitor_1;
      picks.competitor_2 = competitor_2;

      if (!bSlip || bSlip === '' || bSlip === "") {

        bSlip = [];
      }

      if (bSlip.length > 0) {

        vm.jQuery.each(bSlip, function (k, v) {

          if (v !== undefined && v !== null) {

            if (v.odd_id === odd_id) {

              isunselect = true;
            }

            if (v.match_id === match_id) {

              delete bSlip[k];
            }
          }
        });
      }

      if (!isunselect) {

        bSlip.push(picks);
      }

      var bt = [];

      vm.jQuery.each(bSlip, function (k, v) {

        if (v !== undefined && v) {

          bt.push(v);
        }

      });

      this.saveObject("bslip", bt);
      this.autoRefreshUI(this.$vnode.tag);

    },
    getOddDirectionClass: function (outcome) {

      if(parseInt(outcome.active) === 0 || parseInt(outcome.status) !== 0) {

        return '';
      }

      if(parseFloat(outcome.odd) >= parseFloat(outcome.previous_odds) ) {

        return 'arrow green up';

      }

      return 'arrow red down';

    },

    getClasses: function (o) {

      return "btn-odd small-txt mr-1 mt-1 outright-"+ o.odd_id;

    },

  },
  mounted: function () {

    /*
    var vm = this;

    this.EventBus.$on('odds:'+vm.id,function(payload){

      vm.oddStatus = payload.status;
      vm.oddActive = payload.active;

      if(parseFloat(payload.odd) !== parseFloat(vm.odd)) {

        vm.previousOdds = vm.odd;
        vm.odd = payload.odd;
      }

      vm.locked = parseInt(vm.oddStatus) > 0 || parseInt(vm.oddActive) === 0 || parseInt(vm.producerStatus) === 0;
      vm.getStatusClass();

      vm.directionClass = vm.getOddDirectionClass();

    });

    this.EventBus.$on('producer:status',function(payload){

      var producer_id = payload.producer_id;
      var producer_status = payload.producer_status;

      if(parseInt(producer_id) === 1 ) {

        vm.producerStatus = producer_status;
        vm.directionClass = vm.getOddDirectionClass();
        vm.locked = parseInt(vm.oddStatus) > 0 || parseInt(vm.oddActive) === 0 || parseInt(vm.producerStatus) === 0;
        vm.getStatusClass();
      }

    });
    */

  },
  data: function (){
    return {
      show_direction: false
    }
  }
}
</script>