<template>

  <div v-show="!hidden">

    <div class="trend-item p-1 league" @click="loadData">

      <div class="d-flex">

        <div class="trend-text1 mr-1 pr-2">
          <span class="bold" v-text="category_name"></span> / <span v-text="competition.tournament_name"></span>
        </div>

      </div>

      <div class="trend-text">

      <span v-if="parseInt(competition.outright_count) === 0">
        <span class="numbers">{{ competition.outright_count }}</span>
        <span style="position: relative;top: 6px;">
          <arrow-right-icon style="height: 1em;"></arrow-right-icon>
        </span>
      </span>

        <span v-else>
        <span class="numbers">{{ competition.outright_count }}</span>
        <span style="position: relative;top: 6px;">
          <arrow-right-icon style="height: 1em;"></arrow-right-icon>
        </span>

      </span>
      </div>

    </div>

    <Outcome
        v-show="visible"
        v-for="(f,index) in competition.outright_odds"
        v-bind:key="getKey(index)"
        v-bind:tournament_id="competition.tournament_id"
        v-bind:outcome="f"
        v-bind:category_name="category_name"
        v-bind:sport_id="sport_id"
        v-bind:tournament_name="competition.tournament_name">
    </Outcome>

    <div class="timeline-wrapper" v-show="busy">

      <div class="timeline-item">

        <div class="animated-background facebook">

          <!-- lines -->

          <div class="background-masker" style="top: 0px; left: 0px; width: 60%; height: 7px; "></div>

          <div class="background-masker" style="top: 17px; left: 0px; width: 60%; height: 7px; "></div>

          <div class="background-masker" style="top: 34px; left: 0px; width: 60%; height: 7px; "></div>

          <div class="background-masker" style="top: 51px; left: 0px; width: 60%; height: 7px; "></div>

          <div class="background-masker" style="bottom: 0px; left: 0px; width: 60%; height: 5px; "></div>

          <!-- odds separator -->
          <div class="background-masker" style="top: 0; bottom: 0; left: 59%; width: 1%; "></div>

          <div class="background-masker" style="top: 0px; right: 0px; width: 40%; height: 7px; "></div>
          <div class="background-masker" style="bottom: 0px; right: 0px; width: 40%; height: 7px; "></div>

          <!-- odds separator -->
          <div class="background-masker" style="top: 0; bottom: 0; left: 79%; width: 1%; "></div>

        </div>

      </div>

    </div>

  </div>

</template>

<script>

import Outcome from './Outcome'

export default {
  name: "Market",
  mounted: function () {

    this.visible = this.is_visible;

  },
  methods: {
    setBusy: function () {

      this.busy = true;

    },
    unBusy: function () {

      this.busy = false;

    },
    setFixture: function (fixtures) {

      this.fixtures = fixtures;
      this.visible = true;

    },
    toggleVisibility: function () {

      this.visible = !this.visible;

    },

    loadData: function () {

      this.toggleVisibility();

    },


    sportIcon: function (sport_id) {
      return this.getSportIcon(sport_id);
    },

    getKey: function (index) {

      var prefix = "fixture-" + index;
      return Math.random().toString(10).replace('0.', 'match-id-' + prefix + '-');

    },
  },
  computed: {
    market_id: function () {
      return this.$store.state.market_id;
    },
    hour: function () {
      return this.$store.state.hour;
    },
    competition_id: function () {
      return this.$store.state.competition_id;
    },
  },
  components: {
    Outcome,
  },
  data: function () {
    return {
      has_fixture: false,
      visible: false,
      busy: false,
      hidden: false,
    }
  },
  props: {
    competition: {
      required: true,
    },
    is_visible: {
      required: false,
      default: false
    },
    is_busy: {
      required: false,
      default: false
    },
    category_name: {
      required: true,
    },
    sport_id: {
      required: true,
    },
  },
}

</script>